import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Request } from '../interfaces/request';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private apiUrl: string = environment.API_URL;

    constructor(private http: HttpClient) {}

    private createHttpObservable(endpoint: string, requestOptions: Request, apiVersion?: string): Observable<any> {
        const method = requestOptions.method;
        const body = requestOptions.body || {};
        const url = `${this.apiUrl}${endpoint}`;

        const requestData: any = {
            ...requestOptions,
        };

        delete requestData.url;
        delete requestData.method;
        delete requestData.body;

        switch (method) {
            case 'get':
                return this.http.get(url, requestData);
            case 'post':
                return this.http.post(url, body, requestData);
            case 'put':
                return this.http.put(url, body, requestData);
            case 'patch':
                return this.http.patch(url, body, requestData);
            case 'delete':
                return this.http.delete(url, requestData);
            default:
                throw new Error(`Método HTTP não suportado: ${method}`);
        }
    }

    public get(endpoint: string, requestOptions?: Partial<Request>) {
        return this.createRequest('get', endpoint, requestOptions);
    }

    public put(endpoint: string, requestOptions?: Partial<Request>) {
        return this.createRequest('put', endpoint, requestOptions);
    }

    public post(endpoint: string, requestOptions?: Partial<Request>) {
        return this.createRequest('post', endpoint, requestOptions);
    }

    public patch(endpoint: string, requestOptions?: Partial<Request>) {
        return this.createRequest('patch', endpoint, requestOptions);
    }

    public delete(endpoint: string, requestOptions?: Partial<Request>) {
        return this.createRequest('delete', endpoint, requestOptions);
    }

    private createRequest(method: string, endpoint: string, requestOptions?: Partial<Request>): Observable<any> {
        return this.createHttpObservable(endpoint, {
            ...requestOptions,
            method: method,
            url: endpoint,
        });
    }
}
