<main class="main">
    <mat-drawer-container [hasBackdrop]="true">
        <mat-drawer #drawer [mode]="'push'" class="max-w-80-p">
            <app-menu></app-menu>
        </mat-drawer>
        <mat-drawer-content class="background-bg">
            <div class="mx-auto w-100-p max-w-1400">
                <app-header [drawer]="drawer"></app-header>
                <router-outlet></router-outlet>
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
</main>
