import { NavigationExtras, Router } from '@angular/router';

import { AuthService } from 'src/app/auth/services/auth.service';
import { Component } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    isUserLogged: boolean;
    user: any;
    currentModule: string;

    constructor(
        private drawer: MatDrawer,
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.currentModule = location.pathname.split('/')[1];
        this.isUserLogged = this.authService.isLogged();
        this.user = this.authService.getLoggedUser();
    }

    logout() {
        for (var key in localStorage) {
            if (localStorage.hasOwnProperty(key) && key !== 'appId') {
                localStorage.removeItem(key);
            }
        }
        location.reload();
    }

    handleNavigate(path: string): void {
        const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true,
        };
        this.drawer.close();
        this.router.navigate([path], navigationExtras);
    }

    closeDrawer(): void {
        this.drawer.close();
    }
}
