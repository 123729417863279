<mat-list class="p-24--force">
    <ng-container *ngIf="isUserLogged && user?.full_name">
        <mat-list-item>
            <p class="fs-20 fw-500 text-label text-wrap">Usuário: {{ user.full_name }}</p>
        </mat-list-item>
        <div class="h-12"></div>
    </ng-container>

    <ng-container *ngIf="!isUserLogged">
        <mat-list-item>
            <p class="fs-16 fw-500 pointer">
                <a (click)="handleNavigate('login')">Login</a>
            </p>
        </mat-list-item>
        <mat-divider></mat-divider>
    </ng-container>

    <!-- <mat-list-item *ngIf="currentModule === 'doacoes'">
        <p class="fs-16 fw-500">
            <a [routerLink]="'/doacoes'" (click)="closeDrawer()">Doações</a>
        </p>
    </mat-list-item -->
    <mat-list-item *ngIf="isUserLogged && currentModule === 'eventos'">
        <p class="fs-16 fw-500">
            <a [routerLink]="'/eventos/meus-tickets'" (click)="closeDrawer()">Meus Tickets</a>
        </p>
    </mat-list-item>
    <!--mat-list-item *ngIf="currentModule === 'buscador'">
        <p class="fs-16 fw-500">
            <a [routerLink]="'/buscador'" (click)="closeDrawer()">Buscador de células</a>
        </p>
    </mat-list-item> -->

    <ng-container *ngIf="isUserLogged">
        <mat-divider></mat-divider>
        <mat-list-item><a class="fs-16 fw-500" (click)="logout()">Sair</a></mat-list-item>
    </ng-container>
</mat-list>
