import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Subject} from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl implements OnInit, OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    OF_LABEL = 'of';

    constructor() {
        super();
        this.getAndInitTranslations();
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAndInitTranslations() {
        this.itemsPerPageLabel = 'Itens por página';
        this.nextPageLabel = 'Próxima página';
        this.previousPageLabel = 'Página anterior';
        this.OF_LABEL = 'de';
        this.changes.next();
    }

    override getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
    };
}
