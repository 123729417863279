import { RouterModule, Routes } from '@angular/router';

import { AppIdGuard } from './common/guard/app-id/app-id.guard';
import { NgModule } from '@angular/core';
import { authGuard } from './common/guard/auth.guard';
import { KidsModule } from './kids/kids.module';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [authGuard],
    },
    {
        path: '',
        loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
        canActivate: [authGuard],
    },
    {
        path: '',
        loadChildren: () => import('./donations/donations.module').then(m => m.DonationsModule),
        canActivate: [authGuard],
    },
    {
        path: '',
        loadChildren: () => import('./membership/membership.module').then(m => m.MembershipModule),
        canActivate: [authGuard],
    },
    {
        path: '',
        loadChildren: () => import('./cells/cells.module').then(m => m.CellsModule),
        canActivate: [authGuard],
    },
    {
        path: '',
        loadChildren: () => import('./kids/kids.module').then(m => m.KidsModule),
        canActivate: [authGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
