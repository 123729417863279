<header class="flex-column flex-center h-104">
    <div class="d-flex flex-space-between">
        <div class="flex-start">
            <button matcha-button size="md" pill="true" color="primary" icon="true" (click)="drawer.toggle()">
                <span class="i-matcha-menu"></span>
            </button>
        </div>
        <div class="d-flex flex-center-center text-center">
            <div class="d-flex flex-center-center gap-24" [ngClass]="{ 'content-center': !getTitle() }">
                <img src="../../../../assets/icons/inchurch.svg" alt="Logo" class="logo" />
                <h4 *ngIf="getTitle()" class="title-header m-0">{{ getTitle() }}</h4>
            </div>
        </div>
        <div class="flex-end">
            <button matcha-button size="md" pill="true" color="primary" icon="true" (click)="toggleSwitch()">
                <span *ngIf="!switchTheme" class="i-matcha-dark-mode-2"></span>
                <span *ngIf="switchTheme" class="i-matcha-light-mode"></span>
                <!-- <mat-icon class="secondary-text">brightness_4</mat-icon> -->
            </button>
        </div>
    </div>
</header>
<!-- TOP BACKGROUND -->
<div class="primary position-absolute place-top place-left place-right z-index--1 h-128"></div>
<!-- / TOP BACKGROUND -->
