import { ActivatedRoute, Route, Router } from '@angular/router';

import { AuthService } from './auth/services/auth.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private appId: string;
    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
    ) {

    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.appId = params['app_id'];
            if (this.appId) {
                const decodedAppId = atob(this.appId);
                const storedAppId = localStorage.getItem('appId');

                if (storedAppId && storedAppId !== decodedAppId) {
                    this.authService.clearLoginData();
                }

                localStorage.setItem('appId', decodedAppId);
            }
        });

        this.reditectOldRoutes();
    }

    private reditectOldRoutes(): void {
        const hash = window.location.hash;

        if (hash.startsWith('#/membership')) {
            const params = hash.replace('#/membership/', '');
            const paramsData = JSON.parse(atob(params));
            this.router.navigateByUrl(`/membresia?app_id=${btoa(paramsData.app_id)}`);
        } else if (hash.startsWith('#/event')) {
            const params = hash.replace('#/event/', '').split('?');
            this.router.navigateByUrl(`/eventos/inscricao-evento/${params[0]}?${params[1]}`);
        } else if (hash.startsWith('#/donation_types')) {
            const params = hash.replace('#/donation_types/', '').split('?');
            const decodeParams = atob(params[0]);
            const dataParams = JSON.parse(decodeParams);

            console.log(dataParams);

            localStorage.setItem('appId', dataParams.app_id);
            localStorage.setItem('tertiary_group', dataParams.tertiarygroup_id);

            this.router.navigateByUrl(`/doacoes`);
        } else if (hash.startsWith('#/donation_payment')) {
            const params = hash.replace('#/donation_payment/', '').split('?');
            this.router.navigateByUrl(`/donation_payment/${params[0]}?${params[1]}`);
        } else if (hash.startsWith('#/register')) {
            this.router.navigateByUrl('/register');
        } else if (hash.startsWith('#/forgot_password')) {
            this.router.navigateByUrl('/forgot-password');
        } else if (hash.startsWith('#/redefine_password/')) {
            const token = hash.replace('#/redefine_password/', '');
            this.router.navigateByUrl(`/redefine-password/${token}`);
        } else if (hash.startsWith('#/account_confirmation/')) {
            const token = hash.replace('#/account_confirmation/', '');
            this.router.navigateByUrl(`/account-confirmation/${token}`);
        }
    }
}
